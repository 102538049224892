
import WebFont from 'webfontloader'

WebFont.load({
  custom: {
    urls: [
      '//use.fontawesome.com/releases/v6.4.2/css/all.css'
    ]
  },
})
